.page-block{
  display:flex;
  flex-direction: row;
  padding-top: 16px;
  justify-content: center;
  padding-bottom: 3vh;
}

.page-details{
  display:flex;
  flex-direction: column;
}

.main-rec-image{
  height: auto;
  max-width: 300px;
}

.record-det-artist{
  font-family: $font-type-b;
  font-size: $text-size-xxl;
  font-weight: $font-w-bold;
  margin: 0px;
  text-shadow: $image-shadow;
  
}
.record-det-title{
  font-size: $text-size-xl;
  font-weight: $font-w-normal;
  margin: 0px;
  font-style: italic;
}

.genre-block {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.main-genre{
  font-family: $font-type-b;
  font-size: $text-size-m;
  padding: 2px 3px;
  border-radius: 3px;
  color: $secondary-color;
  width: auto;
  display:inline-block;
  margin: 0 3px;
  background-color: $label-two;
  margin-bottom: 6px;
  box-shadow: $image-shadow;
}
.main-style{
  @extend .main-genre;
  background-color: $label-one;
}

.tracklist-record{
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.track-rec{
  font-family: $font-type-b;
  font-size: $text-size-m;
  padding: 3px 0;
  display: inline;
  border-bottom: 1px solid rgb(243, 233, 233);
  
  & span{
    padding: 0 3px;
    border-radius: 5px;
    font-size: $text-size-s;
    margin: 0 5px;
  }

  & i {
    padding-left: 10px;
  }

  & .heading-rec{
    font-size: $text-size-m;
    font-weight: 400;
    margin: 0px;
    margin-right: 0.25em;
    font-style: italic;
    border-radius: 3px;
    color: $mid-sub;
    padding: 2px 4px;
    align-self: center;
    margin-left: auto;
    text-align: right;
  }
}
  .label-track{
    background-color: $secondary-color;
    color: $secondary-darker;
  }

  .country-rec{
    font-family: $font-type-b;
    font-size: $text-size-m;
    font-weight: $font-w-normal;
  }
  .year{
    font-family: $font-type-b;
    font-size: $text-size-m;
    font-weight: $font-w-bold;
  }
  .format-rec{
    font-family: $font-type-b;
    font-size: $text-size-m;
    font-weight: $font-w-normal;
  }
  .rec-label{
    font-family: $font-type-b;
    font-size: $text-size-l;
    font-weight: $font-w-bold;
    
    & span {
      padding-right: 5px;
      font-size: $text-size-m;
      text-transform: uppercase; 
      font-weight: $font-w-normal;
    }
  }

  .format-year{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0;

    & div {
      padding-right: 8px;
    }
  }

  .label-block {
    display: flex;
    flex-direction: row;
  }

  .page-desc{
    font-family: $font-type-b;
    font-size: $text-size-m;
    padding: 3px 0;

    strong{
      text-shadow: $image-shadow;
    }

    &span{
      font-style: $font-w-bold;
    }
  }

  .page-name{
    @extend .record-det-artist;
    text-transform: capitalize;
  }

  .web-page {
    font-family: $font-type-b;
    font-size: $text-size-m;
    text-decoration: none;
    padding: 3px 0;
    strong{
      text-shadow: $image-shadow;
    }
  }

  .single-aliases{
    font-family: $font-type-b;
    font-size: $text-size-m;
    text-decoration: none;
    margin: 1px;
  }

  .label-contact{
    font-family: $font-type-b;
    font-size: $text-size-m;
    text-decoration: none;
    padding: 3px 0;
    strong{
      text-shadow: $image-shadow;
    }
  }


  .page-h1{
    font-family: $font-type-b;
    font-size: $text-size-xl;
    display:flex;
    flex-direction: row;
    justify-content: center;
    display: flex;
    flex-direction: row;
    line-height: 23px;
    align-items: center;
    text-shadow: $image-shadow;
    
  }

  .page-real-name{
    @extend .record-det-title
  }


.buttonAddArticle{
  background-color: #de774e;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  height: 23px;
  margin-left:5px;
  vertical-align: center;
  box-shadow: $image-shadow;
  padding-top: 5px;
}

.page-h1 .svg-inline--fa{
  font-size: $text-size-s;
  color: black;
  vertical-align:middle;
  padding-right: 5px;
}


@media all and (max-width: 750px) {
  
  .page-block{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw;
    padding-bottom: 5vh;
  }

  .main-genre {
    display: block;
    flex: 1 0 40%
  }

  .record-details {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
}

}

