.login-block{
  font-family: $font-type-b;
  font-size: $text-size-s;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:0 auto;
}

.logModules{
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}

.signup-block{
  font-family: $font-type-b;
  font-size: $text-size-s;
  width: 350px;
  height: auto;
  margin:0 auto;

}

input[type=text], input[type=password], input[type=email]  {
  width: 100%;
  padding: 8px 8px;
  margin: 4px 0;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  border-top: white;
  border-left: white;
  border-right:white;
  box-sizing: border-box;
  border-radius: $radius-a;
  text-shadow: $image-shadow;
  
}

.buttonSubmit {
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: $radius-a;
  padding: 5px;
  cursor: pointer;
  box-shadow: $image-shadow;
  width: 100%;

  &:hover {
  opacity: 0.8;
  }
}

.loginContainer {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

.low-bar-form{
  display: flex;
  flex-direction: row;
  & :last-child{
    margin-left: auto;
  }
}

.log-error{
  margin-top: 16px;
  width: 240px;
  font-style: italic;
}


// ----- Contact Box ----- //

.contact-box {
  font-family: $font-type-b;
  font-size: $text-size-s;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:0 auto;
  align-items: center;

  & input{
    margin-top: 10px 0;
  }

}

.text-area-msg{
  margin-top: 5px;
  width: 400px;
  height: 200px;
  padding: 3px;
  resize: none;
  border: 1px solid rgb(190, 184, 184);
  border-radius: $radius-a;
  box-shadow: $image-shadow;
  font-family: $font-type-b;
  margin-bottom: 5px;
}

.msgSent{

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & p{
    font-family: $font-type-b;
    font-size: $text-size-m;
    padding-top: 10px;
  }
}

.airplane{
  height: 200px;
}

.page-desc-about{
  font-family: $font-type-b;
  font-size: $text-size-m;
  padding: 3px 15px;

  strong{
    text-shadow: $image-shadow;
  }

  &span{
    font-style: $font-w-bold;
  }
}

@media all and (max-width: 750px) {
  .contact-box {
    width: 350px;

  }
  .text-area-msg{
    width: 350px; 
  }
}

@media all and (max-width: 600px) {
  .page-desc-about {
    font-family: "Lato", sans-serif;
    font-size: 1em;
    padding: 3px 15px;
    width: auto;
}
  .text-area-msg{
    width: 315px; 
  }

  .signup-block{
    width: 300px;
  }

  .loginContainer {
    padding: 8px;
    width: auto;
  }
  
  .contact-box {
    width: auto;
    padding: 0 5px;

    form {
      width: 80%
    }
    .text-area-msg{
      width: 100%
    }
}
}