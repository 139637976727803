
.carousel-small {
  position: relative;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 20px;
  transition: transform ease-out 0.45s;

  figure{
    width: 280px;
    height: 280px;
    overflow: hidden;
    margin: 10px;
    position: relative;
  
    img {
      height: 280px;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      display: block;
      
    }
  
  }
}

.arrows-overlay-small{
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
}

  .left-arr-small{
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    width: 20px;
    height: 30px;
    left: 10px;
    opacity: 0;
    transition: opacity 800ms;
  }

  .right-arr-small{
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    width: 20px;
    height: 30px;
    right: 10px;
    opacity: 0;
    transition: opacity 800ms;
  }
  .arrows-caro-small{
    color: $primary-color;
    height: 10px;
    background: rgba(179, 42, 42, 0.151);
    border-radius: 50%;
  }
  .svg-inline--fa.fa-w-14 {
    width: 10px;
    padding: 5px;
  }

 .carousel-small:hover .right-arr-small, .carousel-small:hover .left-arr-small
    {
        opacity: 1.0;
    }

@media all and (max-width: 350px) {

  
  .carousel-small {
      height: 220px;
      width: 220px;
  }

    .carousel-small  figure{
      width: 200px;
      height: 200px;
    
      img {
        height: 200px;
      }
  }
}

///// -------- Header -------- /////

.carousel-header {
  position: relative;
  height: 300px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  padding-top: 20px;
  transition: transform ease-out 0.45s;


  figure{
    width: auto;
    height: 580px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    background: $secondary-color;
  
    img {
      height: 190px;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      display: block;
      padding-bottom: 60px;
      border: white solid 10px;
    }
  
  }
}

.arrows-overlay-header{
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
}

  .left-arr-header{
    position: absolute;
    top:45%;
    transform:translateY(-50%);
    width: 20px;
    height: 30px;
    left: 20px;
    opacity: 0;
    transition: opacity 800ms;
  }

  .right-arr-header{
    position: absolute;
    top:45%;
    transform:translateY(-50%);
    width: 20px;
    height: 30px;
    right: 20px;
    opacity: 0;
    transition: opacity 800ms;
  }
  .arrows-caro-header{
    color: $primary-color;
    height: 10px;
    background: rgba(179, 42, 42, 0.151);
    border-radius: 50%;
  }
  .svg-inline--fa.fa-w-14 {
    width: 10px;
    padding: 5px;
  }

 .carousel-header:hover .right-arr-header, .carousel-header:hover .left-arr-header
    {
        opacity: 1.0;
    }

  .title-overlay-header {
    background: $primary-color;
    height: 60px;
    width: 100%;
    opacity: 0.7;
    bottom: 0;
    left: 0;
    position: absolute;
    padding: 0;
    transition: opacity .5s;
  
    p {
      color: white;
      line-height: 60px;
      font-family: $font-type-b;
      font-size: $text-size-l;
      text-align: center;
      bottom: 0;
      left: 0;
      position: absolute;
      padding: 0;
      text-align: center;
      height: 100%;
      width: 100%;
      
    }
  }
  
  .highlight{
    background-color: $secondary-darker;
    border-radius: $radius-a;
    padding: 10px;
  }

  .dots-line{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

@media all and (max-width: 600px) {

  
  .carousel-header {
      height: 210px;
      width: 98%;
      margin: 0;
      padding: 3px;
  }

    .carousel-header  figure{
      width: auto;
      height: 200px;
    
      img {
        height: 120px;
      }
  }

  .left-arr-header{
    top:50%;
    left: 20px;

  }

  .right-arr-header{
    top:50%;
    right: 20px;
  }

  .dots-line{
    display:none;
  }

  .title-overlay-header {

    height: 40px;
    
    p{
    line-height: 40px;
    font-family: $font-type-b;
    font-size: $text-size-s;
  
    }
  }

  .highlight{padding: 5px;}
}
