.RecordList-sm{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: space-around;
  align-content: stretch;
  font-family: $font-type-b;
  padding-bottom: 15px;
}

.list-card-sm{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 165px;
  padding: $five-pix;
  border-radius: $radius-b;
  margin: $five-pix;
}

.list-card-type-a-sm{
  font-size: $font-size-card-el;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: $dark-sub;
  border-radius: 3px;
  color: $clear-color;
  padding: 2px 4px;
  align-self: center;
}

.list-card-type-l-sm{
  font-size: $font-size-card-el;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: $high-sub;
  border-radius: 3px;
  color: $clear-color;
  padding: 2px 4px;
  align-self: center;
}

.list-card-t-line-sm{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.list-card-line-sm{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow:1;
  margin: 2px 2px;
}


.list-card-title-sm{
  font-size: $font-size-card;
  font-weight: 400;
  margin: 0px;
  font-style: italic;
}

.list-card-artist-sm{
  font-size: $font-size-card;
  font-weight: 700;
  margin: 0px;
  
}

.list-card-year-sm{
  font-size: $font-size-card;
  font-weight: 300;
  margin: 0px;
}

.list-card-catno-sm{
  font-size: $font-size-card;
  font-weight: 500;
  margin: 0px;
}

.list-card-sm:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.figure-card-sm{
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: center;

  & .img-card-sm{
    
    max-height: 150px;
    width: auto;
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto;
    
  }
}

.list-card-body-sm{
  padding: 2px 12px;
  margin: 0px;
  background-color: $secondary-color;
  border-bottom-left-radius: $radius-b;
  border-bottom-right-radius: $radius-b;
}

@media all and (max-width: 750px) {


}