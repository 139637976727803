.dropdown{
  font-family: $font-type-b;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  position: absolute;
  background-color: white;
  cursor: pointer;

  .buttonMenu{
    font-size: $text-size-xl;
    font-weight: 700;
    padding-top:10px;

  }
  ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: 100vmax;

  }
  li{
    list-style: none;
    text-align: center;
    padding: 20px;
    font-size: $text-size-xl;
    font-weight: 700;
    cursor: pointer;
    
    &:hover{
      color: $primary-darker;
    }
  }

  .svg-inline--fa.fa-w-10{
    color: $primary-color;
}
}

