@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Open+Sans:300,400,700);
.full-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #360606;
  line-height: 28px;
  padding-top: 6px;
  padding-right: 8px;
  width: auto; }

.svg-inline--fa {
  color: #e0dede; }

.logo-ll {
  margin-left: 10px; }
  .logo-ll a {
    color: #e0dede;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    font-weight: 700; }

.nav-bar {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #360606;
  align-content: baseline;
  padding: 0 10px; }
  .nav-bar .nav-links {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    background-color: #360606;
    color: #e0dede;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    font-weight: 700; }
    .nav-bar .nav-links a {
      text-decoration: none;
      color: #e0dede;
      font-weight: 400;
      width: 100%; }
  .nav-bar .titleNav li {
    text-decoration: none;
    list-style-type: none; }
  .nav-bar .nav-links {
    width: 70px;
    font-size: 0.8em;
    justify-content: flex-start;
    align-items: center;
    line-height: 30px; }
  .nav-bar li:hover {
    background-color: #de774ea4; }

@media all and (max-width: 768px) {
  .full-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px; }
  .logo-ll {
    display: none; } }

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  line-height: 30px;
  justify-content: space-between;
  background-color: #360606;
  z-index: 2; }

.foot-bar {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  background-color: #360606;
  align-content: center;
  justify-content: center;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }
  .foot-bar .foot-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #360606;
    color: #e0dede;
    font-size: 0.8em;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    padding: 0 10px; }
    .foot-bar .foot-links a {
      text-decoration: none;
      color: #e0dede;
      font-weight: 400;
      width: 100%; }
  .foot-bar .foot-bar li a .active {
    background-color: #daebd9; }

.foot-logo {
  display: flex;
  flex-direction: row;
  background-color: #360606;
  color: #e0dede;
  justify-content: center;
  align-items: center;
  width: 150px;
  color: white;
  text-decoration: none; }
  .foot-logo img {
    width: 20px;
    height: 20px; }
  .foot-logo p {
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    font-weight: 700; }

.footerAZ {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #360606;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em; }
  .footerAZ p {
    margin: 0;
    padding: 0 5px; }

.rotating-block {
  -webkit-animation: spin1 2s infinite linear;
  animation: spin1 2s infinite linear;
  display: block;
  vertical-align: 12px;
  height: 14px;
  width: 14px; }

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@media all and (max-width: 750px) {
  .footerAZ {
    display: none; } }

@media all and (max-width: 750px) {
  .footerAZ {
    display: none; }
  .foot-logo {
    font-size: small; } }

.search-bar-box {
  overflow: hidden;
  width: 300px;
  vertical-align: middle;
  white-space: nowrap; }

.search-bar-box input#search {
  width: 300px;
  height: 25px;
  background: white;
  border: none;
  font-size: 10pt;
  float: left;
  color: #1d2426;
  padding-left: 23px;
  border-radius: 5px;
  cursor: text; }

.search-bar-box input#search::-webkit-input-placeholder {
  color: #de774e; }

.search-bar-box input#search:-moz-placeholder {
  /* Firefox 18- */
  color: #de774e; }

.search-bar-box input#search::-moz-placeholder {
  /* Firefox 19+ */
  color: #de774e; }

.search-bar-box input#search:-ms-input-placeholder {
  color: #de774e; }

.search-bar-box button.icon {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  background: white;
  height: 21px;
  width: 50px;
  color: #4f5b66;
  opacity: 0;
  font-size: 10pt;
  -webkit-transition: all .55s ease;
  transition: all .55s ease; }

.search-bar-box:hover button.icon, .search-bar-box:active button.icon, .search-bar-box:focus button.icon {
  outline: none;
  opacity: 1;
  margin-left: -50px; }

.search-bar-box:hover button.icon:hover {
  background: white; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  position: relative;
  right: 280px; }

.login-block {
  font-family: "Lato", sans-serif;
  font-size: 0.8em;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

.logModules {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.signup-block {
  font-family: "Lato", sans-serif;
  font-size: 0.8em;
  width: 350px;
  height: auto;
  margin: 0 auto; }

input[type=text], input[type=password], input[type=email] {
  width: 100%;
  padding: 8px 8px;
  margin: 4px 0;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  border-top: white;
  border-left: white;
  border-right: white;
  box-sizing: border-box;
  border-radius: 6px;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.buttonSubmit {
  background-color: #de774e;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  width: 100%; }
  .buttonSubmit:hover {
    opacity: 0.8; }

.loginContainer {
  padding: 16px; }

span.psw {
  float: right;
  padding-top: 16px; }

.low-bar-form {
  display: flex;
  flex-direction: row; }
  .low-bar-form :last-child {
    margin-left: auto; }

.log-error {
  margin-top: 16px;
  width: 240px;
  font-style: italic; }

.contact-box {
  font-family: "Lato", sans-serif;
  font-size: 0.8em;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center; }
  .contact-box input {
    margin-top: 10px 0; }

.text-area-msg {
  margin-top: 5px;
  width: 400px;
  height: 200px;
  padding: 3px;
  resize: none;
  border: 1px solid #beb8b8;
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  font-family: "Lato", sans-serif;
  margin-bottom: 5px; }

.msgSent {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .msgSent p {
    font-family: "Lato", sans-serif;
    font-size: 1em;
    padding-top: 10px; }

.airplane {
  height: 200px; }

.page-desc-about {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 3px 15px; }
  .page-desc-about strong {
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }
  .page-desc-aboutspan {
    font-style: 700; }

@media all and (max-width: 750px) {
  .contact-box {
    width: 350px; }
  .text-area-msg {
    width: 350px; } }

@media all and (max-width: 600px) {
  .page-desc-about {
    font-family: "Lato", sans-serif;
    font-size: 1em;
    padding: 3px 15px;
    width: auto; }
  .text-area-msg {
    width: 315px; }
  .signup-block {
    width: 300px; }
  .loginContainer {
    padding: 8px;
    width: auto; }
  .contact-box {
    width: auto;
    padding: 0 5px; }
    .contact-box form {
      width: 80%; }
    .contact-box .text-area-msg {
      width: 100%; } }

.RecordList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: space-around;
  align-content: stretch;
  font-family: "Lato", sans-serif;
  padding-bottom: 15px; }

.list-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 270px;
  padding: 0.32em;
  border-radius: 10px;
  margin: 0.32em; }

.list-card-type-a {
  font-size: 0.7em;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: purple;
  border-radius: 3px;
  color: white;
  padding: 2px 4px;
  align-self: center; }

.list-card-type-l {
  font-size: 0.7em;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: #008031;
  border-radius: 3px;
  color: white;
  padding: 2px 4px;
  align-self: center; }

.list-card-t-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1; }

.list-card-title {
  font-size: 0.9em;
  font-weight: 400;
  margin: 0px;
  font-style: italic; }

.list-card-artist {
  font-size: 0.9em;
  font-weight: 700;
  margin: 0px; }

.list-card-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin: 2px 2px; }

.list-card-year {
  font-size: 0.9em;
  font-weight: 300;
  margin: 0px; }

.list-card-catno {
  font-size: 0.9em;
  font-weight: 500;
  margin: 0px; }

.list-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); }

.figure-card {
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 10px;
  position: relative; }
  .figure-card .img-card {
    max-height: 250px;
    width: auto;
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto; }

.list-card-body {
  padding: 2px 12px;
  margin: 0px;
  background-color: #daebd9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.RecordList-sm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: space-around;
  align-content: stretch;
  font-family: "Lato", sans-serif;
  padding-bottom: 15px; }

.list-card-sm {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 165px;
  padding: 0.32em;
  border-radius: 10px;
  margin: 0.32em; }

.list-card-type-a-sm {
  font-size: 0.7em;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: purple;
  border-radius: 3px;
  color: white;
  padding: 2px 4px;
  align-self: center; }

.list-card-type-l-sm {
  font-size: 0.7em;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: #008031;
  border-radius: 3px;
  color: white;
  padding: 2px 4px;
  align-self: center; }

.list-card-t-line-sm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; }

.list-card-line-sm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 2px 2px; }

.list-card-title-sm {
  font-size: 0.9em;
  font-weight: 400;
  margin: 0px;
  font-style: italic; }

.list-card-artist-sm {
  font-size: 0.9em;
  font-weight: 700;
  margin: 0px; }

.list-card-year-sm {
  font-size: 0.9em;
  font-weight: 300;
  margin: 0px; }

.list-card-catno-sm {
  font-size: 0.9em;
  font-weight: 500;
  margin: 0px; }

.list-card-sm:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); }

.figure-card-sm {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: center; }
  .figure-card-sm .img-card-sm {
    max-height: 150px;
    width: auto;
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto; }

.list-card-body-sm {
  padding: 2px 12px;
  margin: 0px;
  background-color: #daebd9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.button-line-move {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  margin: 0 0 40px 0; }

.butt-move {
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.page-block {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  justify-content: center;
  padding-bottom: 3vh; }

.page-details {
  display: flex;
  flex-direction: column; }

.main-rec-image {
  height: auto;
  max-width: 300px; }

.record-det-artist, .page-name {
  font-family: "Lato", sans-serif;
  font-size: 2.8em;
  font-weight: 700;
  margin: 0px;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.record-det-title, .page-real-name {
  font-size: 1.8em;
  font-weight: 400;
  margin: 0px;
  font-style: italic; }

.genre-block {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap; }

.main-genre, .main-style {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 2px 3px;
  border-radius: 3px;
  color: #daebd9;
  width: auto;
  display: inline-block;
  margin: 0 3px;
  background-color: #183bad;
  margin-bottom: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.main-style {
  background-color: #a72929; }

.tracklist-record {
  display: flex;
  flex-direction: column;
  padding: 10px 0; }

.track-rec {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 3px 0;
  display: inline;
  border-bottom: 1px solid #f3e9e9; }
  .track-rec span {
    padding: 0 3px;
    border-radius: 5px;
    font-size: 0.8em;
    margin: 0 5px; }
  .track-rec i {
    padding-left: 10px; }
  .track-rec .heading-rec {
    font-size: 1em;
    font-weight: 400;
    margin: 0px;
    margin-right: 0.25em;
    font-style: italic;
    border-radius: 3px;
    color: #603903;
    padding: 2px 4px;
    align-self: center;
    margin-left: auto;
    text-align: right; }

.label-track {
  background-color: #daebd9;
  color: #680e50; }

.country-rec {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  font-weight: 400; }

.year {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  font-weight: 700; }

.format-rec {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  font-weight: 400; }

.rec-label {
  font-family: "Lato", sans-serif;
  font-size: 1.2em;
  font-weight: 700; }
  .rec-label span {
    padding-right: 5px;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 400; }

.format-year {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0; }
  .format-year div {
    padding-right: 8px; }

.label-block {
  display: flex;
  flex-direction: row; }

.page-desc {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 3px 0; }
  .page-desc strong {
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }
  .page-descspan {
    font-style: 700; }

.page-name {
  text-transform: capitalize; }

.web-page {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  text-decoration: none;
  padding: 3px 0; }
  .web-page strong {
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.single-aliases {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  text-decoration: none;
  margin: 1px; }

.label-contact {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  text-decoration: none;
  padding: 3px 0; }
  .label-contact strong {
    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.page-h1 {
  font-family: "Lato", sans-serif;
  font-size: 1.8em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  line-height: 23px;
  align-items: center;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.buttonAddArticle {
  background-color: #de774e;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  height: 23px;
  margin-left: 5px;
  vertical-align: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  padding-top: 5px; }

.page-h1 .svg-inline--fa {
  font-size: 0.8em;
  color: black;
  vertical-align: middle;
  padding-right: 5px; }

@media all and (max-width: 750px) {
  .page-block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw;
    padding-bottom: 5vh; }
  .main-genre, .main-style {
    display: block;
    flex: 1 0 40%; }
  .record-details {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available; } }

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh; }

.spinner {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.list-card-action {
  background-color: #de774e;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 6px;
  color: white; }

.list-card-button, .list-card-button-fav {
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit; }
  .list-card-button :hover, .list-card-button-fav :hover {
    color: #603903; }

.list-card-button-interchange {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit; }
  .list-card-button-interchange .svg-inline--fa {
    color: #ddc142; }

.list-card-button-fav .svg-inline--fa.fa-heart.fa-w-16 {
  color: #c52a2a; }

.table-versions {
  padding-bottom: 40px;
  padding-bottom: 40px;
  font-family: "Lato", sans-serif; }

@media all and (max-width: 750px) {
  .table-versions {
    width: -webkit-fill-available; } }

.slick-slider {
  align-self: center; }
  .slick-slider a {
    text-decoration: none;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    align-self: center; }
    .slick-slider a:visited {
      color: #d65147; }

.slick-slide .slick-active .slick-current {
  width: 170px; }

.slick-prev:before, .slick-next:before {
  color: #d65147 !important; }

.articles-carousel {
  width: 750px;
  padding: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.slick-initialized {
  border-radius: 6px; }

.caro-box {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  justify-content: center;
  padding-bottom: 5vh; }

.article-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 160px; }
  .article-card figure {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0; }
    .article-card figure img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 150px;
      width: auto;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.no-articles-yet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  font-style: italic;
  padding-bottom: 30px; }

@media all and (max-width: 1024px) {
  .articles-carousel {
    width: 500px; } }

@media all and (max-width: 600px) {
  .articles-carousel {
    width: 340px; }
  .slick-prev {
    left: -10px !important; }
  .slick-next {
    right: -10px !important; } }

@media all and (max-width: 330px) {
  .slick-prev {
    display: none !important; }
  .slick-next {
    display: none !important; }
  .slick-slider {
    width: 290px; }
  .article-card {
    display: table-footer-group;
    text-align: left; }
  .article-card figure {
    position: relative;
    width: 130px;
    height: 130px; } }

.modal-container {
  height: 100%;
  width: 100%;
  background-color: 0.43137;
  font-family: "Lato", sans-serif;
  font-size: 0.8em; }

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -250px;
  z-index: 1;
  width: 500px;
  padding: 10px;
  border-radius: 10px;
  background: white;
  border: 1px solid #de774e;
  -webkit-transition: 1.1s ease-out;
  transition: 1.1s ease-out;
  box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible; }
  .modal.off {
    opacity: 0;
    visibility: hidden;
    -webkit-filter: blur(8px);
            filter: blur(8px);
    -webkit-transform: scale(0.33);
            transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2); }
  @supports (offset-rotation: 0deg) {
    .modal {
      offset-rotation: 0deg;
      offset-path: path("M 250,100 S -300,500 -700,-200"); }
      .modal.off {
        offset-distance: 100%; } }
  @media (prefers-reduced-motion) {
    .modal {
      offset-path: none; } }
  .modal h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0; }
  .modal .content {
    padding: 1rem; }
  .modal .actions {
    padding: 0.5rem 1rem;
    text-align: right; }
    .modal .actions button {
      border: 0;
      display: inline-block;
      background: #daebd9;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      line-height: 1; }

#centered-toggle-button {
  position: absolute; }

@media all and (max-width: 600px) {
  .modal {
    width: 300px;
    margin-left: -155px;
    margin-top: -200px; } }

.addComment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  margin: 0 auto;
  padding-bottom: 16px; }
  .addComment textarea {
    width: 350px;
    height: 50px;
    border-radius: 6px;
    resize: none;
    font-size: 1em;
    font-family: "Lato", sans-serif;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.comment-controls {
  align-self: flex-end;
  padding-top: 5px; }

.buttonSend {
  border-radius: 6px;
  color: white;
  padding: 4px;
  background-color: #de774e;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

@media all and (max-width: 750px) {
  .addComment {
    width: 300px; }
    .addComment textarea {
      width: 300px; } }

.comment-box {
  width: 350px;
  border-radius: 6px;
  resize: none;
  font-size: 1em;
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  padding-bottom: 16px; }

@media all and (max-width: 750px) {
  .comment-box {
    width: 300px; } }

.single-comment {
  padding-bottom: 16px; }

.comment-line {
  border-radius: 6px; }

.comment-timestamp {
  background-color: #daebd9;
  font-size: 0.6em;
  font-family: "Lato", sans-serif;
  text-align: right;
  padding: 0 5px;
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.comments-on-page {
  background-color: #de774e;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  text-align: left;
  padding: 0 5px;
  border-radius: 6px;
  text-transform: capitalize; }
  .comments-on-page span {
    text-transform: uppercase;
    font-weight: 700; }
  .comments-on-page a {
    text-decoration: none;
    color: white;
    font-size: 1em;
    cursor: pointer; }

.carousel-small {
  position: relative;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 20px;
  -webkit-transition: -webkit-transform ease-out 0.45s;
  transition: -webkit-transform ease-out 0.45s;
  transition: transform ease-out 0.45s;
  transition: transform ease-out 0.45s, -webkit-transform ease-out 0.45s; }
  .carousel-small figure {
    width: 280px;
    height: 280px;
    overflow: hidden;
    margin: 10px;
    position: relative; }
    .carousel-small figure img {
      height: 280px;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      display: block; }

.arrows-overlay-small {
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute; }

.left-arr-small {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 30px;
  left: 10px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.right-arr-small {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 30px;
  right: 10px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.arrows-caro-small {
  color: #de774e;
  height: 10px;
  background: rgba(179, 42, 42, 0.151);
  border-radius: 50%; }

.svg-inline--fa.fa-w-14 {
  width: 10px;
  padding: 5px; }

.carousel-small:hover .right-arr-small, .carousel-small:hover .left-arr-small {
  opacity: 1.0; }

@media all and (max-width: 350px) {
  .carousel-small {
    height: 220px;
    width: 220px; }
  .carousel-small figure {
    width: 200px;
    height: 200px; }
    .carousel-small figure img {
      height: 200px; } }

.carousel-header {
  position: relative;
  height: 300px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  padding-top: 20px;
  -webkit-transition: -webkit-transform ease-out 0.45s;
  transition: -webkit-transform ease-out 0.45s;
  transition: transform ease-out 0.45s;
  transition: transform ease-out 0.45s, -webkit-transform ease-out 0.45s; }
  .carousel-header figure {
    width: auto;
    height: 580px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    background: #daebd9; }
    .carousel-header figure img {
      height: 190px;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      display: block;
      padding-bottom: 60px;
      border: white solid 10px; }

.arrows-overlay-header {
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute; }

.left-arr-header {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 30px;
  left: 20px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.right-arr-header {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 30px;
  right: 20px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.arrows-caro-header {
  color: #de774e;
  height: 10px;
  background: rgba(179, 42, 42, 0.151);
  border-radius: 50%; }

.svg-inline--fa.fa-w-14 {
  width: 10px;
  padding: 5px; }

.carousel-header:hover .right-arr-header, .carousel-header:hover .left-arr-header {
  opacity: 1.0; }

.title-overlay-header {
  background: #de774e;
  height: 60px;
  width: 100%;
  opacity: 0.7;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s; }
  .title-overlay-header p {
    color: white;
    line-height: 60px;
    font-family: "Lato", sans-serif;
    font-size: 1.2em;
    text-align: center;
    bottom: 0;
    left: 0;
    position: absolute;
    padding: 0;
    text-align: center;
    height: 100%;
    width: 100%; }

.highlight {
  background-color: #680e50;
  border-radius: 6px;
  padding: 10px; }

.dots-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

@media all and (max-width: 600px) {
  .carousel-header {
    height: 210px;
    width: 98%;
    margin: 0;
    padding: 3px; }
  .carousel-header figure {
    width: auto;
    height: 200px; }
    .carousel-header figure img {
      height: 120px; }
  .left-arr-header {
    top: 50%;
    left: 20px; }
  .right-arr-header {
    top: 50%;
    right: 20px; }
  .dots-line {
    display: none; }
  .title-overlay-header {
    height: 40px; }
    .title-overlay-header p {
      line-height: 40px;
      font-family: "Lato", sans-serif;
      font-size: 0.8em; }
  .highlight {
    padding: 5px; } }

.personal-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 50px; }

.pic-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px; }
  .pic-block figure {
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .pic-block img {
    height: 100px; }

.buttonGetBack {
  background-color: white;
  color: #de774e;
  border: none;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  width: 100%; }
  .buttonGetBack:hover {
    opacity: 0.8; }

@media all and (max-width: 750px) {
  .personal-block {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.dropdown {
  font-family: "Lato", sans-serif;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  position: absolute;
  background-color: white;
  cursor: pointer; }
  .dropdown .buttonMenu {
    font-size: 1.8em;
    font-weight: 700;
    padding-top: 10px; }
  .dropdown ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: 100vmax; }
  .dropdown li {
    list-style: none;
    text-align: center;
    padding: 20px;
    font-size: 1.8em;
    font-weight: 700;
    cursor: pointer; }
    .dropdown li:hover {
      color: #d65147; }
  .dropdown .svg-inline--fa.fa-w-10 {
    color: #de774e; }

.icon-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 150px;
  max-width: 900px; }

.icon-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 1 38%;
  padding: 8vh 0; }
  .icon-menu-item .icon-svg {
    height: 130px;
    width: 150px !important;
    color: #d65147; }
  .icon-menu-item p {
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-size: 1.2em;
    text-align: center; }

@media all and (max-width: 550px) {
  .icon-menu-item {
    padding: 8vh 2vh; }
  .icon-menu {
    max-width: 450px; }
  .icon-menu-item .icon-svg {
    height: 80px;
    width: 80px; } }

.multi-carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 0 1 45%;
  padding-bottom: 30px; }

.multi-card-carousel {
  display: flex;
  flex-direction: column;
  width: 150px;
  padding: 5px; }
  .multi-card-carousel a {
    font-family: "Lato", sans-serif;
    font-size: 0.8em;
    text-decoration: none; }

.multi-card-figure {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin: 0; }
  .multi-card-figure img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px; }

.multi-left-arr {
  padding-top: 70px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.multi-right-arr {
  padding-top: 70px;
  opacity: 0;
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms; }

.multi-arrows-caro {
  color: #de774e;
  height: 10px;
  background: rgba(179, 42, 42, 0.151);
  border-radius: 50%; }

.svg-inline--fa.fa-w-14 {
  width: 10px;
  padding: 5px; }

.multi-carousel:hover .multi-right-arr, .multi-carousel:hover .multi-left-arr {
  opacity: 1.0; }

.video-block {
  padding-bottom: 30px; }

.player-wrapper {
  width: 340px;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

ul.playlist {
  float: left;
  font-size: 1em;
  font-weight: bold;
  list-style-type: none;
  padding: 0;
  width: 340px;
  margin: 0;
  height: 84px;
  overflow: hidden;
  overflow-y: scroll; }

ul.playlist li {
  border-bottom: 1px solid #ccc;
  font-weight: 400;
  display: block;
  padding: 4px;
  text-decoration: none;
  -webkit-transition: font-size 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: font-size 0.3s ease 0s, background-color 0.3s ease 0s;
  width: 340px; }

ul.playlist li:hover {
  background: #daebd9;
  color: #680e50; }

ul.playlist li:last-child {
  border: none; }

@media all and (max-width: 350px) {
  .player-wrapper {
    width: 315px; }
  ul.playlist {
    float: left;
    font-size: 0.9em; } }

.textexchange {
  width: 494px;
  height: 50px; }

.interchange-on-page {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 30px; }
  .interchange-on-page p {
    font-family: "Lato", sans-serif;
    font-size: 1em;
    padding: 3px 0;
    display: inline;
    text-align: center;
    font-style: italic;
    padding-bottom: 10px; }

.exchange-tab {
  padding: 8px;
  background-color: #00ced1;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  width: 340px;
  border-radius: 6px;
  margin: 0 auto; }

.exchange-page {
  padding: 8px;
  width: auto;
  max-width: 700px;
  border-radius: 6px;
  margin: 0 auto; }

.page-formatting-offer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 30%; }

.offers-on-page {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.single-exchange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #daebd9;
  width: 320px;
  padding: 5px;
  margin: 0 auto;
  border-radius: 6px;
  margin-bottom: 16px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }
  .single-exchange figure {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    border-radius: 50%; }
    .single-exchange figure img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto; }

.single-exchange-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
  width: 300px; }
  .single-exchange-text .exchange-title {
    font-family: "Lato", sans-serif;
    font-size: 1em;
    font-weight: 700;
    text-align: left; }
  .single-exchange-text .exchange-desc {
    font-family: "Lato", sans-serif;
    font-size: 0.8em;
    text-align: left; }

.offer-icons {
  color: #de774e;
  cursor: pointer;
  padding: 5px 5px; }
  .offer-icons:hover {
    color: #680e50; }

.exchange-record {
  font-family: "Lato", sans-serif;
  font-size: 0.6em;
  font-weight: 300;
  text-transform: uppercase;
  background-color: #680e50;
  padding: 5px;
  border-radius: 6px; }
  .exchange-record a {
    color: white;
    text-decoration: none; }

.user-offer-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  margin: 10px; }
  .user-offer-block figure {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    border-radius: 50%; }
    .user-offer-block figure img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto; }

.user-icon-block {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; }

@media all and (max-width: 600px) {
  .single-exchange {
    padding: 8px;
    width: 260px; }
  .exchange-tab {
    padding: 10px;
    width: 280px; }
  textarea.textexchange {
    width: 294px;
    height: 80px; } }

@media all and (max-width: 350px) {
  .single-exchange {
    padding: 5px;
    width: 260px; }
  .exchange-tab {
    padding: 10px;
    width: 280px; } }

.message-list {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px; }

.messages-block {
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.full-card-message {
  margin: 0 auto;
  padding: 0px;
  width: 300px; }

.email-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px; }
  .email-line span {
    text-shadow: none; }
  .email-line p {
    font-family: "Lato", sans-serif;
    text-align: center;
    text-shadow: none; }
  .email-line figure {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    border-radius: 50%; }
    .email-line figure img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto; }

.email-subject {
  font-family: "Lato", sans-serif;
  font-size: 1em; }

.email-timestamp {
  font-family: "Lato", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: #daebd9;
  border-radius: 3px;
  display: inline;
  color: #680e50;
  padding: 2px 4px;
  align-self: center;
  margin: 10px 5px 0px 0px; }

.body-message {
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 10px;
  border: 1px solid #f0e7e7;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
  background-color: #ffdfb0; }

.message-scroll {
  height: 370px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 5px; }
  .message-scroll::-webkit-scrollbar {
    background-color: #fff;
    width: 16px; }
  .message-scroll::-webkit-scrollbar-track {
    background-color: #ffdfb0; }
  .message-scroll::-webkit-scrollbar-thumb {
    background-color: #c4ca97;
    border-radius: 10px;
    background-clip: content-box;
    border: 5px solid #ffdfb0; }

.user-left {
  text-align: left;
  align-self: flex-start;
  width: 80%;
  background-color: #f9e5fd;
  padding: 3px;
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.user-right {
  text-align: left;
  width: 70%;
  align-self: flex-end;
  background-color: #b2f5f5;
  padding: 3px;
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.single-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px; }

.big-x-message {
  color: #de774e;
  font-size: 1.8em;
  top: 80px;
  left: 240px;
  position: relative;
  padding: 0;
  cursor: pointer;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -webkit-transition: size ease 2s;
  transition: size ease 2s; }
  .big-x-message:hover {
    color: #680e50; }

.send-answer {
  font-size: 1em;
  background-color: #de774e;
  border-radius: 50%;
  padding: 10px;
  margin-top: 10px;
  align-self: center;
  color: white;
  cursor: pointer; }

.write-message {
  display: flex;
  flex-direction: column;
  padding-top: 16px; }
  .write-message .message-textarea {
    width: 97%;
    min-height: 44px;
    border-radius: 6px;
    border: 1px solid #f0e7e7;
    font-size: 1em;
    padding: 3px;
    resize: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    font-family: "Lato", sans-serif; }

html, body, p {
  margin: 0;
  padding: 0; }

body {
  height: 100%;
  width: 100%; }

textarea:focus, input:focus {
  outline: none; }

.title-pages {
  font-family: "Lato", sans-serif;
  font-size: 1.8em;
  text-align: center;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.2); }

.pages-blocks {
  max-width: 950px;
  margin: 0 auto;
  padding-bottom: 50px; }

.about-block {
  max-width: 450px;
  margin: 0 auto;
  padding-bottom: 50px; }

.no-fav-yet {
  padding-top: 30vh;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  font-style: italic;
  text-align: center; }

.extra-top {
  margin-top: 50px; }

.hot-spot-block {
  display: flex;
  flex-direction: row; }

.hot-spot-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 45%;
  justify-content: center;
  max-width: 400px;
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  margin-bottom: 30px; }

.main-page-block {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center; }

.favoBlocks {
  padding-top: 50px; }

@media all and (max-width: 900px) {
  .main-page-block {
    display: flex;
    flex-direction: column;
    align-content: center; } }

@media all and (max-width: 375px) {
  .about-block {
    max-width: 350px; }
  .pages-blocks {
    max-width: 375px;
    margin: 0 0;
    padding-bottom: 50px; } }

