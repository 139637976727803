.table-versions{
  padding-bottom: 40px;
  padding-bottom: 40px;
  font-family: $font-type-b;
}

@media all and (max-width: 750px) {
  .table-versions{
  width: -webkit-fill-available;
  }
}
