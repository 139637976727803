.icon-menu{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 150px;
  max-width: 900px;
}

.icon-menu-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 38%;
  padding: 8vh 0;

  .icon-svg{
    height: 130px;
    width: 150px !important;
    color: $primary-darker;
  }

  p {
    text-decoration: none;
    font-family: $font-type-b;
    font-size: $text-size-l;
    text-align: center;
  }
}

@media all and (max-width: 550px) {
  .icon-menu-item{
    padding: 8vh 2vh;
  }

  .icon-menu{
    max-width: 450px;
  }

  .icon-menu-item{

    .icon-svg{
      height: 80px;
      width: 80px;
  }
  }

}