.textexchange{
  width: 494px;
  height: 50px;
}
.interchange-on-page{
  display:flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 30px;

  p{
    font-family: $font-type-b;
    font-size: $text-size-m;
    padding: 3px 0;
    display: inline;
    text-align: center;
    font-style: italic;
    padding-bottom: 10px;
  }
}


.exchange-tab{
  padding: 8px;
  background-color: $third-color;;
  box-shadow: $image-shadow;
  width: 340px;
  border-radius: $radius-a;
  margin: 0 auto;
}

.exchange-page{
  padding: 8px;
  width: auto;
  max-width: 700px;
  border-radius: $radius-a;
  margin: 0 auto;
}

.page-formatting-offer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 30%;
}

.offers-on-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.single-exchange{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $secondary-color;
  width: 320px;
  padding: 5px;
  margin: 0 auto;
  border-radius: $radius-a;
  margin-bottom: 16px;
  box-shadow: $image-shadow;

  figure{
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: $image-shadow;
    border-radius: 50%;

    img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      
    }
  
  } 

}

.single-exchange-text{

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
  width: 300px;

  .exchange-title{
    font-family: $font-type-b;
    font-size: $text-size-m;
    font-weight: 700;
    text-align: left;
  }
  .exchange-desc{
    font-family: $font-type-b;
    font-size: $text-size-s;
    text-align: left;
  }
}

.offer-icons{
  color: $primary-color;
  cursor: pointer;
  &:hover{
    color: $secondary-darker;
  }
  padding: 5px 5px;
}

.exchange-record{
  font-family: $font-type-b;
  font-size: $text-size-xs;
  font-weight: $font-w-light;
  text-transform: uppercase;
  background-color: $secondary-darker;
  padding: 5px;
  border-radius: $radius-a;
  
  a{
    color: $white;
    text-decoration: none;
  }
}

.user-offer-block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  margin: 10px;
  figure{
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: $image-shadow;
    border-radius: 50%;

    img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      
    }
  
  }
}

.user-icon-block{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media all and (max-width: 600px) {

  .single-exchange{
    padding: 8px;
    width: 260px;
  }

  .exchange-tab{
    padding: 10px;
    width: 280px;
  }

  textarea.textexchange{
      width: 294px;
      height: 80px;

  }

}

@media all and (max-width: 350px) {

  .single-exchange{
    padding: 5px;
    width: 260px;
  }

  .exchange-tab{
    padding: 10px;
    width: 280px;
  }

}