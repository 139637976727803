.comment-box{

  width: 350px;
  border-radius: $radius-a;
  resize: none;
  font-size: $text-size-m;
  font-family: $font-type-b;
  margin: 0 auto;
  padding-bottom: 16px;
}

@media all and (max-width: 750px) {
  .comment-box{ width: 300px;}

}

.single-comment{
  padding-bottom: 16px;
}

.comment-line{
  border-radius: $radius-a;
}

.comment-timestamp{
  background-color: $secondary-color;
  font-size: $text-size-xs;
  font-family: $font-type-b;
  text-align: right;
  padding: 0 5px;
  border-radius: $radius-a;
  box-shadow: $image-shadow;

}

.comments-on-page{
  background-color: $primary-color;
  font-size: $text-size-s;
  font-family: $font-type-b;
  text-align: left;
  padding: 0 5px;
  border-radius: $radius-a;
  text-transform: capitalize;

  span{
    text-transform: uppercase;
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: white;
    font-size: $text-size-m;
    cursor: pointer;
  }
}