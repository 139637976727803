@import './sass/vars';
@import './components/NavBar/NavBar.scss';
@import './components/footer/footer.scss';
@import './components/SearchForm/searchform.scss';
@import './components/account/accountModules.scss';
@import './components/recordList/RecordCard/recordcard.scss';
@import './components/recordList/RecordCard/recordcard-sm.scss';
@import './pages/SearchPage/searchpage.scss';
@import './components/recordDetails/record-details.scss';
@import './components/Loading/loading.scss';
@import './components/recordList/ActionBar/actionbar.scss';
@import './components/recordDetails/VersionList/versions.scss';
@import './components/library/Articles/articles.scss';
@import './components/Modal/modal.scss';
@import './components/comments/AddComment/AddComment.scss';
@import './components/comments/CommentBox/commentbox.scss';
@import './components//Carousel//Carousel.scss';
@import './components/userZone/PersonalDetail/personalDetail.scss';
@import './components/userZone/DropDown/dropdown.scss';
@import './pages/UserArea/userarea.scss';
@import './components//library/MultiCarousel/MultiCarousel.scss';
@import './components/VideoPlayer/VideoPlayer.scss';
@import './components/exchange/exchangeitem.scss';
@import './components/messageList/messages.scss';


html,body,p{
  margin:0;
  padding:0;
}

body{
  height:100%;
  width: 100%;
}

textarea:focus, input:focus{
  outline: none;
}

.title-pages {
  font-family: $font-type-b;
  font-size: $text-size-xl;
  text-align: center;
  text-shadow: $image-shadow;
  
}

.pages-blocks{
  max-width: 950px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.about-block{
  max-width: 450px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.no-fav-yet{
  padding-top: 30vh;
  font-family: $font-type-b;
  font-size: $text-size-m;
  font-style: italic;
  text-align: center;
}

.extra-top{
  margin-top:50px;
}

.hot-spot-block{
  display:flex;
  flex-direction: row;
}
.hot-spot-cards{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 45%;
  justify-content: center;
  max-width: 400px;
  font-family: $font-type-b;
  margin: 0 auto;
  margin-bottom: 30px;

}

.main-page-block{
  display:flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.favoBlocks{
  padding-top: 50px;
}

@media all and (max-width: 900px) {
.main-page-block{
  display:flex;
  flex-direction: column;
  align-content: center;
}
}

@media all and (max-width: 375px) {
  .about-block{
    max-width: 350px;

  }

  .pages-blocks {
    max-width: 375px;
    margin: 0 0;
    padding-bottom: 50px;
}
}
