.slick-slider{
  align-self: center;

  a{
    text-decoration: none;
    text-align: center;
    font-family: $font-type-b;
    font-size: $text-size-s;
    font-weight: $font-w-normal;
    align-self: center;
    &:visited{
      color: $primary-darker;
    }
  }
}


.slick-slide .slick-active .slick-current{
  width: 170px;
}

.slick-prev:before, .slick-next:before{
    color: $primary-darker!important;
}

.articles-carousel{
  width: 750px;
  padding: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slick-initialized{
  border-radius:$radius-a;
}


.caro-box{
  display:flex;
  flex-direction: row;
  padding-top: 16px;
  justify-content: center;
  padding-bottom: 5vh;
}

.article-card{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 160px;


  figure{
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;

    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    img{
      position: absolute;
      left: 50%;
      top: 50%;
      height: 150px;
      width: auto;
      -webkit-transform: translate(-50%,-50%);
          -ms-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
    }
  }
}

.no-articles-yet{
  display:flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  font-family: $font-type-b;
  font-size: $text-size-m;
  font-style: italic;
  padding-bottom: 30px;
  
}



@media all and (max-width: 1024px) {
  .articles-carousel{
    width: 500px;
  }

}

@media all and (max-width: 600px) {
  .articles-carousel{
    width: 340px;
  }

  .slick-prev {
    left: -10px !important;
  }

  .slick-next {
    right: -10px !important;
  }

}

@media all and (max-width: 330px) {

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }

  .slick-slider{
    width: 290px;
  }

  .article-card {
    display: table-footer-group;
    text-align: left;
}

.article-card figure {
  position: relative;
  width: 130px;
  height: 130px;
}
}
