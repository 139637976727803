
.modal-container {
  height: 100%;
  width: 100%;
  background-color: alpha($color: #d668686e);
  font-family: $font-type-b;
  font-size: $text-size-s;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%; 
  margin-left: -250px;
  margin-top: -250px;
  z-index: 1;

  width: 500px;
  padding: 10px;
  border-radius: $radius-b;
  background: white;

  border: 1px solid $primary-color;
  transition: 1.1s ease-out;
  box-shadow: 
    2rem 2rem 2rem  rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);  
  opacity: 1;
  visibility: visible;

  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }
  
  @supports (offset-rotation: 0deg) {
    offset-rotation: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
    &.off {
      offset-distance: 100%;
    }
  }

  @media (prefers-reduced-motion) {
    offset-path: none;
  }

  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
  }
  .content {
    padding: 1rem;
  }
  .actions {

    padding: 0.5rem 1rem;
    text-align: right;

    button {
      border: 0;
      display: inline-block;

      background: $secondary-color;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      line-height: 1;
    }
  }
}

#centered-toggle-button {
  position: absolute;
}



@media all and (max-width: 600px) {
  .modal{ 
    width: 300px;
    margin-left: -155px;
    margin-top: -200px;
  }
}