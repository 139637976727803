@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Open+Sans:300,400,700');

$primary-color: #de774e;
$primary-darker: rgb(214, 81, 71);
$primary-color-alpha: #de774ea4;
$primary-clear: rgb(255, 223, 176);
$secondary-color: #e8e0f4;
$secondary-darker: #680e50;
$third-color: #00ced1;
$navbar-dark: rgb(54, 6, 6);
$navbar-text: rgb(224, 222, 222);
$text-color: #1d2426;
$text-color-inverted: #e4eff1;
$white: white;
$interchange-available:  rgb(221, 193, 66);
$heart: #c52a2a;

$label-one: rgb(167, 41, 41);
$label-two: rgb(24, 59, 173);
$circular-color: #aea04b;

$text-size-xs: 0.6em;
$text-size-s: 0.8em;
$text-size-m: 1em;
$text-size-l: 1.2em;
$text-size-xl: 1.8em;
$text-size-xxl: 2.8em;

$text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

$image-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

$font-type-a: 'Open Sans', sans-serif;
$font-type-b: 'Lato', sans-serif;

$font-w-normal: 400;
$font-w-light: 300;
$font-w-bold: 700;

$radius-a: 6px;
$radius-b: 10px;

// ---- Record Cards ---- //

$secondary-color: #daebd9;
$clear-color: white;
$shadow-color: #684656;
$dark-sub: purple;
$high-sub: rgb(0, 128, 49);
$mid-sub: #603903;
$font-size-card: 0.9em;
$font-size-card-el: 0.7em;
$five-pix: 0.32em;
