.message-list{
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.messages-block{
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.full-card-message{
  margin: 0 auto;
  padding: 0px;
  width: 300px;

}

.email-line{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  span {
    text-shadow: none;
  }

  p{
    font-family: $font-type-b;
    text-align: center;
    text-shadow: none;
  }
  

  figure{
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    box-shadow: $image-shadow;
    border-radius: 50%;

    img {
      max-height: 60px;
      width: auto;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      
    }
  
  }
}


.email-subject{
  font-family: $font-type-b;
  font-size: $text-size-m;
}

.email-timestamp{
  font-family: $font-type-b;
  font-size: $font-size-card-el;
  font-weight: 400;
  margin: 0px;
  margin-right: 0.25em;
  background-color: $secondary-color;
  border-radius: 3px;
  display: inline;
  color: $secondary-darker;
  padding: 2px 4px;
  align-self: center;
  margin: 10px 5px 0px 0px;
}
.body-message{
  font-family: $font-type-b;
  font-size: $text-size-m;
  padding: 10px;
  border: 1px solid rgb(240, 231, 231);
  border-radius: $radius-a;
  display: flex;
  flex-direction: column;
  box-shadow: $image-shadow;
  display:flex;
  flex-direction: column;
  flex: 1 1 70%;
  background-color: $primary-clear;

}

.message-scroll{
  height: 370px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 5px;

  &::-webkit-scrollbar{
    background-color:#fff;
    width:16px;
  }
  &::-webkit-scrollbar-track {
    background-color:$primary-clear;
  }
  &::-webkit-scrollbar-thumb {
    background-color:#c4ca97;
    border-radius: $radius-b;
    background-clip: content-box;
    border:5px solid $primary-clear;
  }

}


.user-left {
  text-align: left;
  align-self: flex-start;
  width: 80%;
  background-color: rgb(249, 229, 253);
  padding: 3px;
  border-radius: $radius-a;
  box-shadow: $image-shadow;
}

.user-right {
  text-align: left;
  width: 70%;
  align-self: flex-end;
  background-color: rgb(178, 245, 245);
  padding: 3px;
  border-radius: $radius-a;
  box-shadow: $image-shadow;
}


.single-message{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
}

.big-x-message{
  color: $primary-color;
  font-size: $text-size-xl;
  top: 80px;
  left: 240px;;
  position: relative;
  padding: 0;
  cursor: pointer;
  text-shadow: $image-shadow;
  transition: size ease 2s;

  &:hover{
    color: $secondary-darker;
  }
}

.send-answer{
  font-size: $text-size-m;
  background-color: $primary-color;
  border-radius: 50%;
  padding: 10px;
  margin-top: 10px;
  align-self: center;
  color:$white;
  cursor: pointer;
}

.write-message{
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .message-textarea{
    
    width:97%;
    min-height: 44px;
    border-radius: $radius-a;
    border: 1px solid rgb(240, 231, 231);
    font-size: $text-size-m;
    padding: 3px;
    resize: none;
    box-shadow: $image-shadow;
    font-family: $font-type-b;
  }
}