

.addComment{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  margin: 0 auto;
  padding-bottom: 16px;
  
  textarea{
  width: 350px;
  height: 50px;
  border-radius: $radius-a;
  resize: none;
  font-size: $text-size-m;
  font-family: $font-type-b;
  box-shadow: $image-shadow;
  }

}

.comment-controls{
  align-self: flex-end;
  padding-top:5px;
}
.buttonSend{

  border-radius: $radius-a;
  color: white;
  padding: 4px;
  background-color: $primary-color;
  box-shadow: $image-shadow;
}

@media all and (max-width: 750px) {
  .addComment{ 
    width: 300px;
  textarea{ width: 300px;}
  }

}