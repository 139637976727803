.personal-block{
  display:flex;
  flex-direction: row;
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.pic-block{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;

  figure{
    height:120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  img{
    height:100px;
  }
}

.buttonGetBack{
  background-color: white;
  color: $primary-color;
  border: none;
  border-radius: $radius-a;
  padding: 5px;
  cursor: pointer;
  width: 100%;

  &:hover {
  opacity: 0.8;
  }
}

@media all and (max-width: 750px) {
  .personal-block{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
}