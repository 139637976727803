.list-card-action{
  background-color: $primary-color;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 6px;
  color: $clear-color;
}

.list-card-button{
  background: none;
	color: $clear-color;
	border: none;
	padding: 0;
	font: inherit;
  outline: inherit;

  & :hover{
    color: $mid-sub;
  }
}


.list-card-button-interchange{
  background: none;
	border: none;
	padding: 0;
	font: inherit;
  outline: inherit;

  .svg-inline--fa{
    color:$interchange-available;
  }
}

.list-card-button-fav{
  @extend .list-card-button;
  .svg-inline--fa.fa-heart.fa-w-16 {
    color: $heart;
  }
}